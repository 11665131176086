@import './variables';

$animSpeed: 0.6s;

.QuestionAnswer {
  margin: 0 auto;
  position: relative;

  //height: 100%;
  flex: 1 1 auto;

  //overflow: hidden;
  width: 100vw;

  //@media (min-width: 800px) {
  //  width: 80vw;
  //}

  transition: background-color $animSpeed;

  transition: opacity 1s;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  &.answer-correct {
    //background-color: #A2C617;

    .card {
      //background-color: #A2C617;
    }
  }

  &.answer-incorrect {
    //background-color: #D20A10;

    .card {
      //background-color: #D20A10;
    }
  }

  .correct-indication {
    transition: opacity 0.2s;
    opacity: 0;
    margin: 1rem;
  }

  &.answered {
    .correct-indication {
      opacity: 1;
    }
  }

  .correct-indication {
    text-align: center;
    font-weight: bold;

    .true {
      color: #007b9b;
    }

    .false {
      color: #ff0000;
    }
  }

  .explanation {
    margin-top: 0.5rem;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &.answered {
    .explanation {
      opacity: 1;
      transition-delay: 0.4s;
    }
  }

  .footer {
    .btn-next {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &.answered {
    .footer {
      .btn-next {
        opacity: 1;
        transition-delay: 1s;
      }
    }
  }

  // when manual sliding (clicking on button), add animation to the card.. but this needs to be off when real dragging
  &.click-slide {
    overflow: hidden;

    .card {
      transition: transform 0.5s;
    }
  }

}

.card {

  border: 0;
  position: absolute;
  transition: background-color $animSpeed;
  background-color: $bg-light;
  width: 100%;
  //height: 100vh;

  @media (min-width: 800px) {
    //width: 50vw;
  }

  margin: 0 auto;
  box-shadow: none;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  //flex: 1;

  justify-content: space-between;
  align-items: stretch;

  &.animate {
    //transition: transform .3s;
    //box-shadow: none;
    //box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  &.dragging {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  &.inactive {
    box-shadow: none;
  }

  .body {
    width: 100%;
    @media (min-width: 670px) {
      width: 50vw;
    }
    margin: 0 auto;
  }

}

.alert {
  display: none;
}

.DraggableCard {
  cursor: move;
  user-select: none;

  &.drag-Left {
    .btn-false {
      color: $red !important;
    }
  }

  &.drag-Right {
    .btn-true {
      color: $green !important;
    }

  }

}
