
$blue-light: white;
$blue-dark: #82cac9;
$green: #A2C614;
$red: #f05a23;

$progress-bg: #0000A0;
$progress-green: $green;
$progress-grey: grey;

$bg-color-light: white;

$bg-light: $bg-color-light;
$bg-dark: $blue-dark;

$header-height: 100px;
$footer-height: 100px;

$progress-height: 5px;
